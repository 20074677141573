import { ProductCode } from "leven-shared-components/typings/products"
import diff, { Diff } from "lib/date/diff"
import { MortalityBaseNonSmoker, MortalityBaseSmoker } from "./enums"
import { PolicyData, PolicyStatus } from "./interfaces"

const policyEndDateWithinMonth = (endDate?: Date): boolean => {
	const months = endDate ? diff(Diff.MONTHS, new Date(), endDate) : -1
	return months === 0 || months === -1
}

const listOfSmokerCodes: Array<string> = Object.values(MortalityBaseSmoker).map(item => item.toLowerCase())
const listOfNonSmokerCodes: Array<string> = Object.values(MortalityBaseNonSmoker).map(item => item.toLowerCase())

export const recentlyExpiredWithAllowedProduct = (policyStatus: PolicyStatus, productCode: ProductCode, endDate?: Date): boolean => {
	if (policyStatus !== PolicyStatus.EXPIRED) {
		return false
	}
	if (![ProductCode.UL, ProductCode.UL_AEG, ProductCode.UL_AEG_ITG, ProductCode.ULAB].includes(productCode)) {
		return false
	}
	if (!policyEndDateWithinMonth(endDate)) {
		return false
	}
	return true
}

export const policyHolderIsSmoker = (policyData: PolicyData): boolean => {
	return policyData.insured.mortalityBase ? listOfSmokerCodes.includes(policyData.insured.mortalityBase.toLowerCase()) : false
}

export const policyHolderIsNonSmoker = (policyData: PolicyData): boolean => {
	return policyData.insured.mortalityBase ? listOfNonSmokerCodes.includes(policyData.insured.mortalityBase.toLowerCase()) : false
}

export const coPolicyHolderIsSmoker = (policyData: PolicyData): boolean => {
	return policyData.coInsured.mortalityBase ? listOfSmokerCodes.includes(policyData.coInsured.mortalityBase.toLowerCase()) : false
}

export const coPolicyHolderIsNonSmoker = (policyData: PolicyData): boolean => {
	return policyData.coInsured.mortalityBase ? listOfNonSmokerCodes.includes(policyData.coInsured.mortalityBase.toLowerCase()) : false
}

export const showNonSmokerDiscountMutation = (policyData: PolicyData): boolean => {
	const policySourceSystem = policyData.sourceSystem ? policyData.sourceSystem.toLocaleLowerCase() : ""
	const isSourceSystemGenerali = ["generali maia", "la64", "level7", "levo"].includes(policySourceSystem)

	return isSourceSystemGenerali && !policyHolderIsNonSmoker(policyData) ||
		!isSourceSystemGenerali && policyHolderIsSmoker(policyData) ||
		!isSourceSystemGenerali && coPolicyHolderIsSmoker(policyData)
}
