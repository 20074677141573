import { Beneficiary, BeneficiaryKind, Policy, ClaimDocument } from "store/policies/types"
import diff, { Diff } from "lib/date/diff"
import { ClauseCode, PolicyStatus} from "leven-shared-components/typings/interfaces"
import { ProductCode } from "leven-shared-components/typings/products"
import { isUndefined } from "lodash-es"
import { recentlyExpiredWithAllowedProduct } from "leven-shared-components/typings/sharedrules"

const disAllowedProduct = (policy: Policy): boolean => policy && ["DIL", "EXP"].includes(policy.productId)
const isExcludedForExpiration = (policy: Policy): boolean => policy && ["DIL", "EXP", "ORV", "ORV1"].includes(policy.productId)

export const pledgedPolicy = (policy: Policy): boolean => !isUndefined(policy.pledgeHolders) && policy.pledgeHolders.length > 0

export const expirationProduct = (policy: Policy): boolean => policy && policy.productId === ProductCode.EXP

export const expiresSoon = (policy: Policy, period: 6 | 9): boolean => {
	if (policy.policyIdentifier.includes("LYS570")) {
		return false
	}
	if (isExcludedForExpiration(policy)) {
		return false
	}
	if (policy && policy.status === PolicyStatus.ACTIVE && (!policy.claim || policy.claim.statusId === -4) &&
		((policy.calculatedMaturityBenefit || 0) > 0 || (policy.maturityBenefit || 0) > 0) && !!policy.endDate) {
		const months = diff(Diff.MONTHS, new Date(), policy.endDate)
		return months >= 0 && months < period
	}

	return false
}

export const canExpire = (policy: Policy): boolean => policy
	&& !policy.policyIdentifier.includes("LYS570")
	&& !isExcludedForExpiration(policy)
	&& policy.status === PolicyStatus.ACTIVE

export const statusExpired = (policy: Policy): boolean => policy
	&& policy.status === PolicyStatus.EXPIRED

export const hasExpirationClaim = (policy: Policy): boolean => policy.claim?.listOfMyPolicies[0].damageCause === "Expiratie"

export const hasRedemptionClaim = (policy: Policy): boolean => policy.claim?.listOfMyPolicies[0].damageCause === "Afkoop"

export const openClaim = (policy: Policy): boolean => policy && !!policy.claim && policy.claim.statusId === -1

export const pendingClaim = (policy: Policy): boolean => policy
	&& !!policy.claim && policy.claim.statusId === -2 && documentsToUpload(policy).length > 0

export const hasOpenOrPendingClaim = (policy: Policy): boolean => openClaim(policy) || pendingClaim(policy)

export const hasOpenOrPendingExpirationClaim = (policy: Policy): boolean => hasOpenOrPendingClaim(policy) && hasExpirationClaim(policy)

export const hasOpenOrPendingRedemptionClaim = (policy: Policy): boolean => hasOpenOrPendingClaim(policy) && hasRedemptionClaim(policy)

export const hasOpenAdvisorClaim = (policy: Policy): boolean => openClaim(policy) && !isUndefined(policy.claim?.identifierForCompletion) &&
	(
		hasExpirationClaim(policy) && !isExcludedForExpiration(policy) && !pledgedPolicy(policy)
		|| hasRedemptionClaim(policy) && !disAllowedProduct(policy)
	)

export const hasOpenSystemExpirationClaim = (policy: Policy): boolean => openClaim(policy)
	&& statusExpired(policy) && !isExcludedForExpiration(policy) && isUndefined(policy.claim?.identifierForCompletion)

export const pendingExpirationClaim = (policy: Policy): boolean => pendingClaim(policy) && hasExpirationClaim(policy)

export const pendingRedemptionClaim = (policy: Policy): boolean => pendingClaim(policy) && hasRedemptionClaim(policy)

export const pendingDone = (policy: Policy): boolean => policy
	&& !!policy.claim && policy.claim.statusId === -2 && (documentsToUpload(policy).length === 0)

export const documentStatusInfo = (policy: Policy, statusId: string): Array<ClaimDocument> => {
	if (policy && policy.claim) {
		return policy.claim.requestedInformation.filter(document => document.statusId === statusId)
	}
	return []
}

export const documentsToUpload = (policy: Policy): Array<ClaimDocument> => documentStatusInfo(policy, "toBeReceived")

export const documentsUploaded = (policy: Policy): boolean => documentStatusInfo(policy, "awaitingApproval").length > 0
	|| documentStatusInfo(policy, "accepted").length > 0

export const approved = (policy: Policy): boolean => policy
	&& !!policy.claim && policy.claim.statusId === -3

export const redeemable = (policy: Policy): boolean => {
	if (policy && disAllowedProduct(policy)) {
		return false
	}
	return policy && policy.status === PolicyStatus.ACTIVE && (!policy.claim || policy.claim.statusId === -4)
}

export const isMoneyMaxxPolicy = (policy: Policy): boolean => {
	return policy.clause?.code === ClauseCode.AEG_BELGIE
}

export const customerOnlyHasMoneyMaxxProducts = (listOfPolicies: Policy[]): boolean => {
	return listOfPolicies.every(isMoneyMaxxPolicy)
}

export const isExcludedFromPayoutProcess = (policy: Policy): boolean => {
	const excludedClauses = [ClauseCode.LYF_ALIMTT, ClauseCode.KAP_LYF_PU, ClauseCode.LYF_INVKND, ClauseCode.LYF_SALDO,
		ClauseCode.LYF_NETTO, ClauseCode.LEVENSLOOP, ClauseCode.PW, ClauseCode.KAP_ERFRNT]

	const isExcludedClause = policy.clause ? excludedClauses.includes(policy.clause.code) || isMoneyMaxxPolicy(policy) : false

	return disAllowedProduct(policy) || isExcludedClause
}

export const policyEndDateTodayOrInThePast = (endDate?: Date): boolean => {
	const days = endDate ? diff(Diff.DAYS, new Date(), endDate) : -1
	return days === 0 || days < 0
}

export const policyEndedNoClaimYet = (policy: Policy): boolean => {
	return policy.status === PolicyStatus.ACTIVE && policyEndDateTodayOrInThePast(policy.endDate) && !policy.claim
}

export const changePolicyAllowed = (policy: Policy): boolean => {
	if (statusExpired(policy) && !recentlyExpiredWithAllowedProduct(policy.status, policy.productId, policy.endDate)
		|| !statusExpired(policy) && (policy.claim && [-1, -2, -3].includes(policy.claim.statusId))
		|| policyEndedNoClaimYet(policy)
		|| isMoneyMaxxPolicy(policy)
	) {
		return false
	}
	return true
}

export const lifeCourseSavingsScheme = (policy: Policy): boolean => {
	if (!policy.policyIdentifier.includes("LYS570") && policy.clause?.code === ClauseCode.LEVENSLOOP) {
		return true
	}
	return false
}

export const packageRiskPolicy = (policy: Policy): boolean => policy
	&& ["ORV", "ORV1"].includes(policy.productId) && policy.packagePolicy

export const activePackageRiskPolicy = (policy: Policy): boolean => packageRiskPolicy(policy)
	&& policy.status === PolicyStatus.ACTIVE

export const packageAccrualPolicy = (policy: Policy): boolean => policy
	&& !["ORV", "ORV1"].includes(policy.productId) && policy.packagePolicy

export const isPackageAccrualPolicyRedeemed = (policy: Policy): boolean => packageAccrualPolicy(policy) && hasRedemptionClaim(policy)

export const policyHolderIsNotPrimaryBeneficiary = (policy: Policy): boolean => {
	const isFirstBeneficiaryWhileAlive = (beneficiary: Beneficiary): boolean =>
		beneficiary.sequence === 1 && (beneficiary.kind === BeneficiaryKind.NVT || beneficiary.kind === BeneficiaryKind.WHILEALIVE)

	const isNotPolicyholder = (beneficiary: Beneficiary): boolean => {
		const isBeneficiaryTypeNotPolicyholder: boolean =
			beneficiary.type ?
				!["eerste verzekeringnemer", "eerste verzekerde"].includes(beneficiary.type.toLowerCase()) ||
				beneficiary.type?.toLowerCase() === "eerste verzekerde" && policy.insuredExtId !== policy.policyHolderExtId :
			false

		const isBeneficiaryNameNotPolicyholder: boolean = beneficiary.name ? beneficiary.customerExternalId !== policy.policyHolderExtId : false

		return isBeneficiaryTypeNotPolicyholder || isBeneficiaryNameNotPolicyholder
	}

	return policy.beneficiaries.some(
		beneficiary => isFirstBeneficiaryWhileAlive(beneficiary) && isNotPolicyholder(beneficiary)
	)
}
